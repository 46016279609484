<template>
	<div id="patientInfo">
	</div>
</template>

<script>
	import {
		// getPatient, // 获取患者信息
		// getPatientMeeting // 获取患者会诊记录
	} from '@/request/api.js';
	export default {
		name: 'patientInfo',
		components: {},
		data() {
			return {
				patientId: '',
				// patientInfo: '',
				patientMeeting: [],
				total: 0,
				size: 5,
				page: 1
			}
		},
		created() {
			this.patientId = this.$route.params.id;
			// this.getPatientFun();
			// this.getPatientMeetingFun();
		},
		mounted() {},
		methods: {
			// 跳转会诊详情
			toHuiyiDetails(item) {
				this.$router.push({
					name: 'huiyiDetails',
					params: {
						id: item.id
					}
				})
			},

			getPatientFun() {
				let data = {
					id: this.patientId
				}
				getPatient(data).then(res => {
					if (res.code == 200) {
						this.patientInfo = res.data;
					}
				})
			},

			getPatientMeetingFun() {
				let data = {
					id: this.patientId,
					current: this.page,
					size: this.size
				}
				getPatientMeeting(data).then(res => {
					if (res.code == 200) {
						this.patientMeeting = res.data.records;
						this.total = res.data.total;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#patientInfo {
		padding: 30px 20px;

		h1 {
			font-size: 22px;
			margin-bottom: 30px;
		}

		.userInfo {
			margin-bottom: 30px;

			.el-row {
				margin-bottom: 20px;

				.t {
					font-size: 14px;
					padding: 0 10px;
					margin-bottom: 10px;
					color: rgba(17, 41, 80, 1);
				}

				p {
					font-size: 16px;
					padding: 0 10px 10px;
					border-bottom: 1px solid rgba(229, 229, 229, 1);
				}

				.el-image {
					width: 110px;
					height: 110px;
					margin: 0 10px 10px 0;
				}
			}
		}

		.meetingList {
			.item {
				display: flex;
				padding: 20px;
				border-radius: 10px;
				margin-bottom: 20px;
				flex-direction: column;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);

				.tit {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-bottom: 10px;
					justify-content: space-between;

					p {
						display: flex;
						font-size: 14px;
						flex-direction: row;
						align-items: center;
						color: rgba(61, 68, 79, 1);

						img {
							height: 16px;
							margin-right: 5px;
						}
					}

					span {
						font-size: 12px;
						color: rgba(153, 153, 153, 1);
					}
				}

				.name,
				.date {
					font-size: 16px;
					margin-bottom: 10px;
					color: rgba(61, 68, 79, 1);
				}

				.opts {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;

					.type {
						.el-link {
							margin-right: 10px;
						}
					}
				}
			}

		}
	}
</style>